import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { init, sendForm } from 'emailjs-com';
import './contact-form.scss';

init('user_SYti0GKxSY6wgUrPZAKoq');

export default function ContactForm() {

  const { register, handleSubmit, formState: { errors } } = useForm();
  const [contactNumber, setContactNumber] = useState("000000");
  const [statusMessage, setStatusMessage] = useState();
  let form2 = null;

  setTimeout(() => {
    form2 = document.getElementById('contact-form');
  }, 200)



  const generateContactNumber = () => {
    const numStr = "000000" + (Math.random() * 1000000 | 0);
    setContactNumber(numStr.substring(numStr.length - 6));
    console.log(contactNumber)
  }

  const onSubmit = (data) => {
    generateContactNumber();
    sendForm('default_service', 'template_7z8u0b8', '#contact-form')
      .then(function (response) {
        console.log('SUCCESS!', response.status, response.text);
        setStatusMessage("Uw bericht is verzonden");
        form2.reset();

      }, function (error) {
        console.log('FAILED...', error);
        setStatusMessage("Er is iets fout gegaan. Probeer het nog een keer");
      });
  }

  return (
    <form id='contact-form' onSubmit={handleSubmit(onSubmit)}>
      {statusMessage && (<div className="col-12 form-row">
        <p className='status-message'>{statusMessage}</p>
      </div>)}

      <div className="col-6 form-row">
        <label htmlFor="user_name">Naam</label>
        <input
          id="user_name"
          aria-invalid={errors.name ? "true" : "false"}
          {...register("user_name", { required: true, maxLength: 30 })}
        />
        {errors.user_name && errors.user_name.type === "required" && (
          <span role="alert">* Naam ontbreekt</span>
        )}
      </div>

      <div className="col-6 form-row">
        <label htmlFor="user_email">Email</label>
        <input
          id="user_email"
          {...register("user_email", { required: true, maxLength: 254 })}
        />
        {errors.user_email && errors.user_email.type === "required" && (
          <span role="alert">* Email ontbreekt</span>
        )}
      </div>

      <div className="form-row full">
        <label htmlFor="message">Bericht</label>
        <textarea
          id="message"
          {...register("message", { required: true })}
        />
        {errors.message && errors.message.type === "required" && (
          <span role="alert">* Bericht ontbreekt</span>
        )}
      </div>

      <input type='submit' value='Verstuur' />
    </form>
  );
}