import React from "react";
import './footer.scss';

export default function Footer() {
    return (
        <footer className="footer full-width">
            <div className="col-12">
                <a href="#home" className="logo"><svg xmlns="http://www.w3.org/2000/svg" width="27.712" height="19.166" viewBox="0 0 27.712 19.166"><path d="M612.378,423.2l-13.606,13.606v4.956h27.212v-4.956Zm12.4,13.4h-8.288v-1.063L612.079,434l-4.413,1.535V436.6h-7.688l12.4-12.4Zm-25.3,4.457v-3.751h8.188v3.751Zm8.894,0v-5.018l3.707-1.289,3.707,1.289v5.018Zm8.12,0v-3.751h8.787v3.751Z" transform="translate(-598.522 -422.846)" fill="#96aa98" stroke="#96aa98" strokeWidth="0.5" /></svg></a>
                <div className="info">
                    <ul>
                        <li>
                            <a href="https://www.instagram.com/bbhetvijfdeseizoen/" rel="noreferrer" target="_blank"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 49.6 49.6">
                                <g fill="white">
                                    <path d="M24.8 11.9c-7 0-12.8 5.7-12.8 12.8s5.7 12.8 12.8 12.8 12.8-5.8 12.8-12.8-5.8-12.8-12.8-12.8zm0 21c-4.5 0-8.2-3.7-8.2-8.2s3.7-8.2 8.2-8.2 8.2 3.7 8.2 8.2-3.7 8.2-8.2 8.2z" />
                                    <circle cx="38.1" cy="11.6" r="2.9" />
                                    <path d="M45.6 4.1C43 1.4 39.3 0 35.1 0H14.5C5.8 0 0 5.8 0 14.5V35c0 4.3 1.4 8 4.2 10.7 2.7 2.6 6.3 3.9 10.4 3.9H35c4.3 0 7.9-1.4 10.5-3.9 2.7-2.6 4.1-6.3 4.1-10.6V14.5c0-4.2-1.4-7.8-4-10.4zm-.4 31c0 3.1-1.1 5.6-2.9 7.3S38 45 35 45H14.6c-3 0-5.5-.9-7.3-2.6-1.8-1.8-2.7-4.3-2.7-7.4V14.5c0-3 .9-5.5 2.7-7.3 1.7-1.7 4.3-2.6 7.3-2.6h20.6c3 0 5.5.9 7.3 2.7 1.7 1.8 2.7 4.3 2.7 7.2v20.6z" />
                                </g>
                            </svg>Instagram</a>
                        </li>
                        <li><a href="mailto:nancy@hetvijfdeseizoen.com">nancy@hetvijfdeseizoen.com</a></li>
                    </ul>
                    <ul>
                        <li><p>06 20622565</p></li>
                        <li><p>Middenweg 108,</p></li>
                        <li><p>8538 RA Bantega</p></li>
                    </ul>
                </div>
            </div>
        </footer>
    )
}
