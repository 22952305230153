import React from 'react'
import Flickity from 'react-flickity-component'
import "./carousel.scss";

import image1 from '../images/room/_DSC4391.jpg'
import image2 from '../images/room/_DSC4608.jpg';
import image3 from '../images/room/_DSC4623.jpg';
import image4 from '../images/room/_DSC4671.jpg';
import image5 from '../images/room/_DSC4580.jpg';
import image6 from '../images/room/_DSC4587.jpg';
import image7 from '../images/room/_DSC4596.jpg';
import image8 from '../images/room/_DSC4602.jpg';
import image9 from '../images/room/_DSC4616.jpg';



const flickityOptions = {
    initialIndex: 2,
    autoPlay: true,
    lazyLoad: true,
    pageDots: false,
    wrapAround: true,
    arrowShape: "M 123.4 7.9 H 3.8 L 10.3 1.4 L 8.9 0 L 0 8.9 L 8.9 17.8 L 10.3 16.4 L 3.8 9.9 H 123.4",
    adaptiveHeight: true
}

export default function CarouselRoom() {
    return (
        <Flickity
            className={'carousel room'}
            elementType={'div'}
            options={flickityOptions}
            disableImagesLoaded={false}
            reloadOnUpdate
            static
        >
            <img alt="" src={image1} />
            <img alt="" src={image2} />
            <img alt="" src={image3} />
            <img alt="" src={image4} />
            <img alt="" src={image5} />
            <img alt="" src={image6} />
            <img alt="" src={image7} />
            <img alt="" src={image8} />
            <img alt="" src={image9} />
        </Flickity>
    )
}
