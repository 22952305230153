import React from "react";
import "./pricing.scss";
import { PriceRow } from "./PriceRow";

export default function pricing() {
  return (
    <div className="pricing col-12">
      <div class="col-12 row info">
        <div class="col-5">
          <h2>Prijzen 2024/2025</h2>
          <p>
            Tarieven zijn vanaf 2 nachten en incl. opgemaakte bedden en
            handdoeken, keukenlinnen, WiFi, toeristenbelasting en
            eindschoonmaak. Tarieven zijn excl. €350 borg, die wordt
            teruggestort als alles in goede staat is achtergelaten. Op nationale
            feestdagen geldt een toeslag van 10% op de getoonde prijzen.
          </p>
        </div>
        {/* <div class="col-4">
                    <h2>Vakantie korting</h2>
                    <p>Van 19 t/m 27 februari en 26 februari t/m 6 maart 2022.</p> 
                    <p>5 slaapkamers - max. 10 personen.</p>
                    <p>-</p>
                    <div class="row">
                        <p>Week 7 nachten</p>
                        <p>€1995</p>
                    </div>
                    <div class="row">
                        <p>Midweek 4 nachten</p> 
                        <p>€995</p>
                    </div>
                    
                    <p></p> 
                </div> */}
      </div>
      <div class="col-4">
        <div class="heading">
          <p>01-01 t/m 24-02</p>
        </div>
        <PriceRow time="Weekend 2 nachten" price="1175" />
        <PriceRow time="Lang weekend 3 nachten" price="1305" />
        <PriceRow time="Midweek 4 nachten" price="1250" />
        <PriceRow time="Week 7 nachten" price="2230" />
        <PriceRow time="2 nachten algemeen" price="1040" />
        <PriceRow time="3 nachten algemeen" price="1200" />
      </div>
      <div className="col-4">
        <div class="heading">
          <p>25-02 t/m 30-10</p>
        </div>
        <PriceRow time="Weekend 2 nachten" price="1460" />
        <PriceRow time="Lang weekend 3 nachten" price="1620" />
        <PriceRow time="Midweek 4 nachten" price="1460" />
        <PriceRow time="Week 7 nachten" price="2670" />
        <PriceRow time="2 nachten algemeen" price="1250" />
        <PriceRow time="3 nachten algemeen" price="1410" />
      </div>
      <div className="col-4">
        <div class="heading">
          <p>31-10 t/m 31-12</p>
        </div>
        <PriceRow time="Weekend 2 nachten" price="1120" />
        <PriceRow time="Lang weekend 3 nachten" price="1175" />
        <PriceRow time="Midweek 4 nachten" price="1305" />
        <PriceRow time="Week 7 nachten" price="2230" />
        <PriceRow time="2 nachten algemeen" price="1040" />
        <PriceRow time="3 nachten algemeen" price="1200" />
      </div>
    </div>
  );
}
