import React from 'react';
import './section.scss'
import RoomCarousel from './CarouselRoom'

export default function Section(props) {
    const { img, info } = props.sections

    return (
        <section class="col-12 reverse" id={info[0].subhead}>
            <div class="content col-6">
                {info.map(info => {
                    return <>
                        {info.subhead && <p className="subhead">{info.subhead}</p>}
                        <h2>{info.title}</h2>
                        <p>{info.body} {info.link && <a href="https://www.sterkehein.nl/" target="_blank" rel='noreferrer'>{info.link}</a>}</p>
                    </>
                })}
            </div>
            <div class="image col-5">
                {info.map(info => {
                    return <>
                        {info.subhead === 'overnachten' && <RoomCarousel />}
                        {info.subhead !== 'overnachten' && <img src={img.src} alt={img.alt} />
                        }
                    </>
                })}
            </div>
        </section>
    )
}