import React from 'react'
import Flickity from 'react-flickity-component'
import "./carousel.scss";

import slide1 from '../images/carousel/_DSC4401.jpg';
import slide2 from '../images/carousel/_DSC4405.jpg';
import slide3 from '../images/carousel/_DSC4447.jpg';
import slide4 from '../images/carousel/_DSC4461.jpg';
import slide5 from '../images/carousel/_DSC4624.jpg';
import slide6 from '../images/carousel/_DSC4657.jpg';
import slide7 from '../images/carousel/_DSC4668.jpg';

const flickityOptions = {
    initialIndex: 2,
    autoPlay: true,
    lazyLoad: true,
    pageDots: false,
    wrapAround: true,
    arrowShape: "M 123.4 7.9 H 3.8 L 10.3 1.4 L 8.9 0 L 0 8.9 L 8.9 17.8 L 10.3 16.4 L 3.8 9.9 H 123.4",
    adaptiveHeight: true
}

export default function Carousel() {
    return (
        <Flickity
            className={'carousel'}
            elementType={'div'}
            options={flickityOptions}
            disableImagesLoaded={false}
            reloadOnUpdate
            static
        >
            <img alt="" src={slide1} />
            <img alt="" src={slide2} />
            <img alt="" src={slide4} />
            <img alt="" src={slide3} />
            <img alt="" src={slide6} />
            <img alt="" src={slide5} />
            <img alt="" src={slide7} />

        </Flickity>
    )
}
