import React from "react";
import "./contact.scss";
import Map from "./Map";
import ContactForm from "./ContactForm";

export default function Contact() {
  return (
    <div id="map">
      <div className="col-12">
        <div className="info col-5">
          <a
            className="label"
            href="https://www.natuurhuisje.nl/"
            target="_blank"
          >
            <img
              src="https://www.natuurhuisje.nl/images/stamp/NL.svg"
              title="Vakantiehuizen in de natuur"
              onerror="this.onerror=null; this.src='https://www.natuurhuisje.nl/images/stamp/NL.png'"
            />
          </a>
          <h2>Beschikbaarheid bekijken of een andere vraag?</h2>
          <ul>
            <li>06 20622565</li>
            <li>Middenweg 108</li>
            <li>8538 RA Bantega</li>
          </ul>
          <ContactForm />
        </div>
        <div className="col-6" id="google-map">
          <Map />
        </div>
      </div>
    </div>
  );
}
