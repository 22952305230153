import React from "react";
import Carousel from "./components/Carousel";
import Hero from "./components/Hero";
import Navigation from "./components/Navigation";
import Section from "./components/Section";
import SectionReverse from "./components/SectionReverse";
import Footer from "./components/Footer";
import ScrollAnimation from "react-animate-on-scroll";
import Contact from "./components/Contact";
import "./components/room-carousel.scss";
import Booking from "./components/Booking";
import Pricing from "./components/Pricing";

import "./styles/_main.scss";

import slide1 from "./images/sections/_DSC4573.jpg";
import woonkamer from "./images/carousel/_DSC4668.jpg";
import woonkamer2 from "./images/carousel/_DSC4657.jpg";
import slide3 from "./images/carousel/_DSC4447.jpg";
import slide4 from "./images/sections/_DSC4580.jpg";
import slide5 from "./images/sections/_DSC4405.jpg";
import slide6 from "./images/sections/boat.jpg";

function App() {
  let sections = [
    {
      fullWidth: true,
      reverse: true,
      img: {
        src: slide1,
        alt: "",
        width: 5,
      },
      info: [
        {
          width: 5,
          subhead: "locatie",
          title: "‘Sfeervol overnachten op het Friese platteland’",
          body: "Het Vijfde Seizoen is een sfeervolle groepsaccommodatie gelegen in het weidse landschap van het Friese Bantega. Deze gerenoveerde boerderij vormt niet alleen de perfecte locatie voor familievakanties, maar is ook zeer geschikt voor zakelijke gelegenheden. De ruime, gezamenlijke woonkamer is een heerlijke plek voor gezinnen om samen te koken, eten en spelletjes te spelen maar kan ook dienen als sfeervolle vergaderzaal. Geniet van de prachtige Friese omgeving en neem de boerenlucht tot u op ons riante terras. Het Vijfde Seizoen is van alle gemakken voorzien om uw verblijf zo aangenaam mogelijk te maken. Of u nu komt om te ontspannen of te vergaderen, u bent bij ons aan het juiste adres.",
        },
      ],
    },
    {
      fullWidth: true,
      reverse: false,
      img: {
        src: woonkamer2,
        alt: "",
        width: 5,
      },
      info: [
        {
          width: 5,
          subhead: "Activiteiten & omgeving",
          title: "Ontdek de prachtige omgeving",
          body: "Elektrische fietsen staan voor u klaar om een mooie fietstocht te maken langs de weilanden of om een bezoek te brengen aan de sfeervolle dorpjes in de omgeving zoals Lemmer, Sloten en Langweer. Op korte afstand ligt het Tjeukemeer, waarlangs je een wandeling of fietstocht kunt maken. Vanuit de accommodatie is het 30 minuten rijden naar Nationaal Park Weerribben-Wieden, een indrukwekkend stukje Hollands natuur met een bezoekerscentrum waar je ook een fluisterbootje kunt huren.  Het prachtige Giethoorn ligt op korte afstand daarvan. Voor de golfliefhebbers is er de mooie golfbaan Burg Golf in St Nicolaasga op maar 15 km afstand van onze boerderij.",
        },
      ],
    },
    {
      fullWidth: false,
      reverse: true,
      img: {
        src: slide3,
        alt: "",
        width: 6,
      },
      info: [
        {
          width: 5,
          subhead: "vergaderen",
          title:
            "‘Stimuleer uw creativiteit door de frisse buitenlucht en de mooie omgeving’",
          body: "De frisse buitenlucht van het Friese platteland is de ideale stimulans om tot nieuwe ideeën te komen. Dat maakt onze boerderij de perfecte locatie om inspiratie op te doen bij een zakelijke vergadering. De ruime woonkamer met lange tafel en uitzicht op de landerijen zorgt voor een ontspannen werksfeer. Uw vergadering kan uiteraard gecomplementeerd worden met een lunch, diner en drankjes.",
        },
      ],
    },
    {
      fullWidth: false,
      reverse: false,
      img: {
        src: woonkamer,
        alt: "",
        width: 6,
      },
      info: [
        {
          width: 5,
          subhead: "evenementen",
          title: "Meer dan alleen overnachten",
          body: "De ruime boerderij Het Vijfde Seizoen biedt de perfecte locatie voor verschillende evenementen. De tuin, de woonkamer en ‘de deel’ zijn allemaal inzetbaar voor feesten en partijen en uitermate geschikt voor een geslaagde avond. Van bruiloft, vriend(in)en weekend tot business event, het kan allemaal! Uiteraard in combinatie met onze 5 slaapkamers.",
        },
      ],
    },
    {
      fullWidth: false,
      reverse: true,
      img: {
        src: slide5,
        alt: "",
        width: 6,
      },
      info: [
        {
          width: 5,
          subhead: "foto locatie",
          title: "De perfecte foto- en filmlocatie",
          body: "Uit ervaring blijkt dat in en rondom de boerderij prachtige foto’s te maken zijn. De Friese weilanden en het fotogenieke interieur van de boerderij, maakt Het Vijfde Seizoen de perfecte foto- en filmlocatie voor onder andere fotografen en stylisten. U kunt altijd contact met ons opnemen indien u een dag bij ons wilt komen fotograferen of filmen.",
        },
      ],
    },
    {
      fullWidth: false,
      reverse: false,
      img: {
        src: slide6,
        alt: "",
        width: 6,
      },
      info: [
        {
          width: 5,
          subhead: "sterke hein",
          title: "Maximaal genieten op de Friese wateren",
          body: "Combineer uw bezoek aan het vijfde seizoen met een dag heerlijk zeilen op het IJsselmeer met collega’s, vrienden of familie op Lemsteraak Sterke Hein. Of om uw zakenrelaties in een andere sfeer te ontmoeten. Deze dag staat voor onbezorgd genieten. Lekker zeilen te midden van de vele jachten en traditionele zeilschepen. Meer informatie:",
          link: "www.sterkehein.nl",
        },
      ],
    },
    {
      fullWidth: false,
      reverse: false,
      img: {
        src: slide4,
        alt: "",
        width: 6,
      },
      info: [
        {
          width: 5,
          subhead: "overnachten",
          title:
            "Ontspan en slaap in 1 van de 5 stijlvolle ingerichte slaapkamers",
          body: "Ontspan en slaap in 1 van de 5 stijlvolle ingerichte slaapkamers met ieder een eigen badkamer en eigen sfeer. In de warme ambiance van de woonkamer geniet je van het prachtige uitzicht over de weilanden en kun je heerlijk zitten bij de houtkachel. De professionele keuken is voorzien van een 6-pits gasfornuis,oven,magnetron en een snelle vaatwasser.",
        },
      ],
    },
  ];

  return (
    <div>
      <Navigation />
      <Hero />

      <ScrollAnimation animateIn="fadeInUp" duration={1} delay={100}>
        <Section sections={sections[0]} />
      </ScrollAnimation>

      <ScrollAnimation animateIn="fadeInUp" duration={1} delay={200}>
        <Booking />
      </ScrollAnimation>

      <ScrollAnimation animateIn="fadeInUp" duration={1} delay={100}>
        <Carousel />
      </ScrollAnimation>

      <ScrollAnimation animateIn="fadeInUp" duration={1} delay={200}>
        <SectionReverse sections={sections[1]} />
      </ScrollAnimation>

      <ScrollAnimation animateIn="fadeInUp" duration={1} delay={100}>
        <Section sections={sections[2]} />
      </ScrollAnimation>

      <ScrollAnimation animateIn="fadeInUp" duration={1} delay={100}>
        <SectionReverse sections={sections[3]} />
      </ScrollAnimation>

      <ScrollAnimation animateIn="fadeInUp" duration={1} delay={100}>
        <Section sections={sections[5]} />
      </ScrollAnimation>

      <ScrollAnimation animateIn="fadeInUp" duration={1} delay={100}>
        <SectionReverse sections={sections[6]} />
      </ScrollAnimation>

      <ScrollAnimation animateIn="fadeInUp" duration={1} delay={100}>
        <Pricing />
      </ScrollAnimation>

      <ScrollAnimation animateIn="fadeInUp" duration={1} delay={100}>
        <Contact />
      </ScrollAnimation>

      <Footer />
    </div>
  );
}

export default App;
