import React from "react";
import "./hero.scss";
import { Link } from "react-scroll";

export default function Hero() {
  return (
    <div className="hero fadeIn">
      <div className="info fadeInUp">
        <h1>Sfeervol overnachten op het Friese platteland</h1>
        <Link
          className="book-button"
          to="map"
          smooth={true}
          offset={-100}
          duration={500}
        >
          Boek nu
        </Link>
      </div>
      <div className="bottom">
        <p>Luxe groepsaccommodatie voor 2 - 12 personen</p>
        <span className="charging">
          <img
            alt="Laadpaal aanwezig"
            className="desktop"
            src="/charging_white.svg"
          />
          <p>Laadpaal aanwezig</p>
        </span>
        <span>
          <Link
            className="locatie"
            to="locatie"
            smooth={true}
            offset={-100}
            duration={500}
          >
            Ontdek de locatie
          </Link>
        </span>
      </div>
    </div>
  );
}
