import React from "react";
export const PriceRow = ({ time, price }) => {
  return (
    <>
      <div class="row">
        <p>{time}</p>
        <p>€{price},-</p>
      </div>
    </>
  );
};
