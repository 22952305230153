import React, { useState } from 'react'
import './navigation.scss'
import { Link } from "react-scroll";
import logoDesktop from '../svg/logo_big.svg'
import logoMobile from '../svg/logo.svg'

export default function Navigation() {

    const [open, setOpen] = useState(false)

    const handleOpen = () => {

        let menu = document.querySelector("#navigation");

        if (!open) {
            menu.classList.add('open')
            setOpen(true)
        } else {
            menu.classList.remove('open')
            setOpen(false)
        }
    }

    return (
        <div className="parent-nav">
            <nav id="navigation" className="col-12 navigation stroke">
                <a href="#home" className="logo">
                    <img alt="logo desktop" className='desktop' src={logoDesktop} />
                    <img alt="logo mobile" className='mobile' src={logoMobile} />
                </a>
                <button className="menu-btn" id="menu-btn" onClick={handleOpen}>
                    <label className="menu-icon" htmlFor="menu-btn">
                        <span className="navicon"></span>
                    </label>
                </button>
                <ul className="menu">
                    <li><Link to="map" smooth={true} offset={-100} duration={500} onClick={handleOpen}>Overnachten</Link></li>
                    <li><Link to="vergaderen" smooth={true} offset={-100} duration={500} onClick={handleOpen}>Vergaderen</Link></li>
                    <li><Link to="sterke hein" smooth={true} offset={-100} duration={500} onClick={handleOpen}>Sterke Hein</Link></li>
                    <li><Link to="map" smooth={true} offset={-100} duration={500} onClick={handleOpen}>Boeken</Link></li>
                    <li><Link to="map" smooth={true} offset={-100} duration={500} onClick={handleOpen}>Contact</Link></li>
                </ul>
            </nav>
        </div>
    )
}
