import React from "react";
import "./booking.scss";
import { Link } from "react-scroll";

export default function Booking() {
  return (
    <div>
      <div className="col-12" id="booking-link">
        <script src="https://dashboard.vakantieadressen.nl/js/frontend/widget-iframe.js"></script>
        <iframe
          src="https://dashboard.vakantieadressen.nl/widget/1823/6034dd5e3f738"
          title="Booking module"
          width="100%"
          height="700"
          frameborder="0"
        ></iframe>
        <p>Beschikbaarheid bekijken en direct boeken?</p>
        <Link
          className="booking-button"
          to="map"
          smooth={true}
          offset={-100}
          duration={500}
        >
          Neem contact met ons op
        </Link>
      </div>
    </div>
  );
}
